/* Universal Styles */

html {
  font-size: 18px;
  background-color: #eee;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

#body {
  background-color: #eee;
  padding-bottom: 2.5rem;
}

#charts {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
  background-color: #eee;
  position: relative;
  min-height: 100vh;
}

h1 {
  padding: .66rem 0;
  text-align: center;
  background-color: #cca353;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}