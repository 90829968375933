.NavBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-image: url('./background_search_desktop.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 25rem;
    position: fixed;
}

#name-disp {
    font-size: 1.5rem;
}

#nav-div {
    height: 5rem;
    padding: 0.75rem 0 0 0.75rem;
    box-shadow: 0em 0.35em 1em grey;
}